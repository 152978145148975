import request from '@/js/request'
// 登录
export function Login (data, lang) {
  return request({
    url: '/admin/login?language=' + lang,
    method: 'post',
    data: data
  })
}

// 发送邮箱验证码
export function emailCode (data, lang) {
  return request({
    url: '/admin/sendCode?language=' + lang,
    method: 'post',
    data: data
  })
}

// 修改密码
export function resetPwd (data, lang) {
  return request({
    url: '/admin/reset?language=' + lang,
    method: 'post',
    data: data
  })
}

export function getlanguage () {
  return request({
    url: '/language',
    method: 'get'
  })
}
