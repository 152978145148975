<template>
  <div class="login">
    <div class="login_dialog" v-show="isLogin">
      <div class="login_title">TMC</div>

      <div class="login_content">
        <div class="login_text">{{$t('Login.Account')}}</div>
        <el-input class="login_input" type="text" v-model.trim="loginInfo.username" :placeholder="$t('Setting.IpUserName')" @keyup.enter.native="Login" clearable></el-input>
      </div>
      <div class="login_content" style="margin-top:20px">
        <div class="login_text">{{$t('Login.Password')}}</div>
        <el-input class="login_input" type="password" v-model.trim="loginInfo.password" :placeholder="$t('Setting.IpPwd')" @keyup.enter.native="Login" clearable></el-input>
      </div>
      <div class="login_confire" @click="Login">{{$t('Login.SignIn')}}</div>
      <div class="login_forget" @click='CheckForget'>{{$t('Login.ForgotPassword')}}？</div>
      <div class="login_language">
        <div class="title">{{$t('Login.Language')}}</div>
        <el-radio v-model="radio" label="1" @change="handleSetLanguage('zh')">中文</el-radio>
        <el-radio v-model="radio" label="2" @change="handleSetLanguage('en')">English</el-radio>
      </div>
    </div>
    <div class="login_reset" v-show="!isLogin">
      <div class="reset_content">
        <div class="reset_top">
          <div class="reset_title">{{$t('Login.ResetPassword')}}</div>
          <div class="reset_space"></div>
          <div class="reset_back" @click="backLogin">{{$t('Login.Backtologinpage')}}</div>
        </div>
        <div class="login_tab">
          <div class='tab1' @click="checkTab('0')" :style="{borderColor:tab=='0'?'#1883ee':''}">{{$t('Login.Email')}}</div>
          <div class='tab2' @click="checkTab('1')" :style="{borderColor:tab=='1'?'#1883ee':''}" style="margin-left:30px">{{$t('Login.MobilePhone')}}</div>
        </div>
      <div v-if="tab=='0'" class="mailbox">
        <el-form
          ref="loginEmail"
          :model="loginEmail"
          :rules="mailboxRules"
          class="login-form"
          auto-complete="new-password"
          label-position="left"
        >
          <div class="login_item">
            <div class="login_list">
              <div class="input-title">{{$t('Login.Email')}}</div>
              <el-form-item prop="email">
                <el-input
                  ref="email"
                  v-model.trim="loginEmail.email"
                  :placeholder="$t('Setting.IptEmail')"
                  clearable
                  type="text"
                  tabindex="1"
                  auto-complete="new-password"
                  class="el-input-setHeight"
                  @keyup.enter.native="handleResetPwd('0')"
                />
              </el-form-item>
            </div>
            <div class="login_list" style="margin-left:30px">
              <div class="input-title">{{$t('Login.VerificationCode')}}</div>
              <el-form-item prop="EmailCode">
                <el-input
                  ref="EmailCode"
                  v-model.trim="loginEmail.code"
                  type="text"
                  :placeholder="$t('Login.verificationCode')"
                  clearable
                  tabindex="2"
                  auto-complete="new-password"
                  class="el-input-setHeight"
                  @keyup.enter.native="handleResetPwd('0')"
                />
              </el-form-item>
            </div>
            <div class="login_code" :style="{backgroundColor:isSendEmail?'#8e99af':'#4875ef'}" @click="sendEmailCode">
              <span v-show="!isSendEmail">{{$t('Login.SendVerificationCode')}}</span>
              <span v-show="isSendEmail">{{ count }} s</span>
            </div>
          </div>
          <div class="login_item">
            <div class="login_list">
              <div class="input-title">{{$t('Login.ResetPassword')}}</div>
              <el-form-item prop="EmailSetPassword">
                <el-input
                  ref="EmailSetPassword"
                  v-model.trim="loginEmail.SetPassword"
                  :placeholder="$t('Login.resetThePassword')"
                  type="password"
                  clearable
                  tabindex="1"
                  auto-complete="new-password"
                  class="el-input-setHeight"
                  @keyup.enter.native="handleResetPwd('0')"
                />
              </el-form-item>
            </div>
            <div class="login_list" style="margin-left:30px">
              <div class="input-title">{{$t('Login.ConfirmPassword')}}</div>
              <el-form-item prop="EmailConfirePassword">
                <el-input
                  ref="EmailConfirePassword"
                  v-model.trim="loginEmail.ConfirePassword"
                  type="password"
                  :placeholder="$t('Login.newpassword')"
                  clearable
                  tabindex="2"
                  auto-complete="new-password"
                  class="el-input-setHeight"
                  @keyup.enter.native="handleResetPwd('0')"
                />
              </el-form-item>
            </div>
            <div class="login_code" style="background:#dcdde2;color:#dcdde2">{{$t('Login.SendVerificationCode')}}
            </div>
          </div>

          <div class="login_btn" @click="handleResetPwd('0')">{{$t('Login.OK')}}</div>

        </el-form>
      </div>
      <div v-if="tab=='1'" class="mailbox">
        <el-form
          ref="loginPhone"
          :model="loginPhone"
          :rules="phoneboxRules"
          class="login-form"
          auto-complete="new-password"
          label-position="left"
        >
          <div class="login_item">
            <div class="login_list">
              <div class="input-title">{{$t('Login.MobilePhone')}}</div>
              <el-form-item prop="phone">
                <el-input
                  ref="phone"
                  v-model.trim="loginPhone.phone"
                  :placeholder="$t('Login.phoneNumber')"
                  clearable
                  type="text"
                  tabindex="1"
                  auto-complete="new-password"
                  class="el-input-setHeight"
                  onkeyup="this.value = this.value.replace(/[^\d.]/g,'');"
                  maxlength="11"
                  @keyup.enter.native="handleResetPwd('1')"
                />
              </el-form-item>
            </div>
            <div class="login_list" style="margin-left:30px">
              <div class="input-title">{{$t('Login.VerificationCode')}}</div>
              <el-form-item prop="PhoneCode">
                <el-input
                  ref="PhoneCode"
                  v-model.trim="loginPhone.code"
                  type="password"
                  :placeholder="$t('Login.verificationCode')"
                  clearable
                  tabindex="2"
                  auto-complete="new-password"
                  class="el-input-setHeight"
                  @keyup.enter.native="handleResetPwd('1')"
                />
              </el-form-item>
            </div>
            <div class="login_code" :style="{backgroundColor:isSendEmail?'#8e99af':'#4875ef'}" @click="sendPhoneCode">
              <span v-show="!isSendEmail">{{$t('Login.SendVerificationCode')}}</span>
              <span v-show="isSendEmail">{{ count }} s</span>
            </div>
          </div>
          <div class="login_item">
            <div class="login_list">
              <div class="input-title">{{$t('Login.ResetPassword')}}</div>
              <el-form-item prop="PhoneSetPassword">
                <el-input
                  ref="PhoneSetPassword"
                  v-model.trim="loginPhone.SetPassword"
                  :placeholder="$t('Login.resetThePassword')"
                  clearable
                  type="password"
                  tabindex="1"
                  auto-complete="new-password"
                  class="el-input-setHeight"
                />
              </el-form-item>
            </div>
            <div class="login_list" style="margin-left:30px">
              <div class="input-title">{{$t('Login.ConfirmPassword')}}</div>
              <el-form-item prop="PhoneConfirePassword">
                <el-input
                  ref="PhoneConfirePassword"
                  v-model.trim="loginPhone.ConfirePassword"
                  type="password"
                  clearable
                  :placeholder="$t('Login.newpassword')"
                  tabindex="2"
                  auto-complete="new-password"
                  class="el-input-setHeight"
                  @keyup.enter.native="handleResetPwd('1')"
                />
              </el-form-item>
            </div>
            <div class="login_code" style="background:#dcdde2;color:#dcdde2">{{$t('Login.SendVerificationCode')}}
            </div>
          </div>

          <div class="login_btn" @click="handleResetPwd('1')">{{$t('Login.OK')}}</div>

        </el-form>
      </div>
      </div>

    </div>
  </div>
</template>
<style lang="less" scoped>
.login{
  width: 100%;
  height: 100%;
  background-image: url('../../assets/image/bgs.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  /deep/ .el-form-item__content {
    line-height: 32px;
    height: 32px;
    position: relative;
    font-size: 14px;
  }
  .login_reset{
    width: 900px;
    height: 440px;
    background: #dcdde2;
    filter: drop-shadow(0 16px 14px rgba(0, 0, 0, 0.16));
    border-radius: 20px;
    .reset_content{
      width: 100%;
      height: 100%;
      padding: 40px;
      .mailbox{
        .login-form{
          margin-top: 30px;
          /deep/ .el-input__suffix{
            top: -3px;
          }
          .login_btn{
            width: 80px;
            height: 34px;
            line-height: 34px;
            text-align: center;
            color: #f2f3f6;
            float: right;
            font-size: 13px;
            border-radius: 16px;
            background: #4875ef;
            margin-top: 25px;
            cursor: pointer;
          }
          .login_item{
            display: flex;
            flex-direction: row;
            align-items: flex-end;
            .login_list{
              flex: 1;
              border-radius: 10px;
              /deep/ .el-input__inner{
                border-radius: 6px;
                height: 32px;
                line-height: 32px;
              }
              .input-title{
                text-align: left;
                padding: 10px 0;
                font-size: 18px;
              }
            }
            .login_code{
              margin-left:30px;
              height: 32px;
              margin-bottom: 22px;
              padding: 0 20px;
              min-width: 110px;
              border-radius: 20px;
              font-size: 13px;
              line-height: 32px;
              text-align: center;
              color: #f2f3f6;
              background: #8e99af;
              cursor: pointer;
            }
          }
        }
      }

      .login_tab{
        display: flex;
        flex-direction: row;
        margin-top: 20px;
        .tab1 , .tab2{
          height: 30px;
          line-height: 30px;
          font-weight: 400;
          font-size: 18px;
          line-height: 13px;
          text-align: left;
          color: #0e2a43;
          border-bottom: 4px solid transparent;
          cursor: pointer;
        }
      }
      .reset_top{
        display: flex;
        flex-direction: row;
        .reset_title{
          width: 200px;
          font-weight: 400;
          font-size: 18px;
          line-height: 18px;
          height: 31px;
          text-align: left;
          color: #4d4f5c;
        }
        .reset_space{
          flex: 1;
        }
        .reset_back{
          min-width: 110px;
          padding: 0 20px;
          height: 31px;
          background: #a4b9d1;
          text-align: center;
          line-height: 31px;
          font-size: 13px;
          color: #f2f3f6;
          border-radius: 20px;
          cursor: pointer;
        }
      }
    }
  }
  .login_dialog{
    width: 480px;
    height: 480px;
    background: #dcdde2;
    filter: drop-shadow(0 16px 14px rgba(0, 0, 0, 0.16));
    border-radius: 20px;
    /deep/ .el-input__suffix{
      top: 2px;
    }
    .login_title{
      font-size: 32px;
      letter-spacing: 0.5em;
      text-align: left;
      color: #0e2a43;
      text-align: center;
      padding: 60px 0 15px 0;
    }
    .login_content{
      padding: 0 50px;
      text-align: left;
      .login_text{
        font-size: 18px;
      }
      .login_input{
        width: 100%;
        height: 48px;
        border: none;
        margin-top: 5px;
        /deep/ .el-input__inner{
          height: 100%;
          border: none;
          border-radius: 4px;
          background: #9f9fa5;
          font-size: 14px;
          color: #fff;
          padding: 0 25px;
        }
      }
    }
    .login_confire{
      margin: 0 50px;
      text-align: center;
      margin-top: 40px;
      line-height: 48px;
      color: #fff;
      border-radius: 4px;
      letter-spacing: 1px;
      font-size: 14px;
      height: 48px;
      background: #4875ef;
      filter: drop-shadow(0 3px 12px rgba(0, 0, 0, 0.16));
      cursor: pointer;
    }
    .login_forget{
      margin: 20px 0;
      font-size: 14px;
      letter-spacing: 0.02em;
      text-align: center;
      color: #868688;
      opacity: 0.8;
      cursor: pointer;
    }
    .login_language{
      margin: 20px 0;
      font-size: 14px;
      letter-spacing: 0.02em;
      color: #4d565c;
      display: flex;
      justify-content: center;
      align-items: center;
      .title{
        margin-right: 30px;
        padding-bottom: 6px;
      }
    }
  }
}

</style>
<script>
import { Login, emailCode, resetPwd, getlanguage } from '@/api/login'
export default {
  name: 'login',
  components: { },
  data () {
    return {
      repeat: true,
      repeatMail: true,
      repeatPhone: true,
      isSendEmail: false, // 是否发送邮件
      count: 0, // 倒计时秒数
      timer: null, // 倒计时定时器
      // 手机号修改密码对象
      loginPhone: {
        SetPassword: '',
        ConfirePassword: '',
        code: '',
        phone: ''
      },
      // 邮箱修改密码对象
      loginEmail: {
        SetPassword: '',
        ConfirePassword: '',
        code: '',
        email: ''
      },
      isLogin: true, // 区分是登陆弹窗还是修改密码弹窗
      tab: '0', // 手机号修改还是邮箱修改区分对象
      radio: '1', // 中英文切换
      // 登陆表单
      loginInfo: {
        username: '',
        password: ''
      },
      workShop: {} // 车间对象
    }
  },
  computed: {
    // 邮箱重置密码验证
    mailboxRules () {
      return {
        code: [{ required: true, message: this.$t('Login.VerificationCode'), trigger: 'blur' }],
        SetPassword: [{ required: true, message: this.$t('Setting.IpPwd'), trigger: 'blur' }],
        email: [
          { required: true, message: this.$t('Setting.IptEmail'), trigger: 'blur' },
          { type: 'email', message: this.$t('Tip.IncorrectEmail'), trigger: 'blur' }
        ]
      }
    },
    // 手机号重置密码验证
    phoneboxRules () {
      var checkphone = (rule, value, callback) => {
        if (!this.isCellPhone(value)) { // 引入methods中封装的检查手机格式的方法
          callback(new Error(this.$t('Tip.Format')))
        } else {
          callback()
        }
      }
      return {
        code: [{ required: true, message: this.$t('Login.VerificationCode'), trigger: 'blur' }],
        SetPassword: [{ required: true, message: this.$t('Setting.IpPwd'), trigger: 'blur' }],
        phone: [
          { required: true, message: this.$t('Login.phoneNumber'), trigger: 'blur' },
          { validator: checkphone, trigger: 'blur' }
        ]
      }
    }

  },
  created () {
    this.getlanguage()
  },
  mounted () {
    if (localStorage.getItem('language')) {
      this.$i18n.locale = localStorage.getItem('language') === 'zh_CN' ? 'zh' : 'en'
      this.radio = localStorage.getItem('language') === 'zh_CN' ? '1' : '2'
    } else {
      localStorage.setItem('language', 'zh_CN')
      this.$i18n.locale = 'zh'
      this.radio = '1'
    }
  },
  methods: {
    // 清空表单
    resetForm () {
      this.loginEmail = {
        SetPassword: '',
        ConfirePassword: '',
        code: '',
        email: ''
      }
      this.loginPhone = {
        SetPassword: '',
        ConfirePassword: '',
        code: '',
        email: ''
      }
    },
    // 重置密码API
    handleResetPwd (val) {
      const params = {}
      params.password = val === '0' ? this.loginEmail.SetPassword : this.loginPhone.SetPassword
      params.verify_body = val === '0' ? this.loginEmail.email : this.loginPhone.phone
      params.verify_code = val === '0' ? this.loginEmail.code : this.loginPhone.code
      params.send_type = val === '0' ? 'email' : 'mobile'
      const lang = localStorage.getItem('language')
      // 表单校验是否通过
      if (val === '0') {
        this.$refs.loginEmail.validate((valid) => {
          if (valid) {
            if (!this.repeatMail) {
              return
            }
            this.repeatMail = false
            setTimeout(() => {
              this.repeatMail = true
            }, 2000)
            if (!params.password) {
              this.$message({
                showClose: true,
                message: this.$t('Setting.IpPwd'),
                type: 'warning',
                duration: 1000
              })
              return
            }
            if (!params.verify_code) {
              this.$message({
                showClose: true,
                message: this.$t('Login.verificationCode'),
                type: 'warning',
                duration: 1000
              })
              return
            }
            if (this.loginEmail.SetPassword !== this.loginEmail.ConfirePassword) {
              this.$message({
                showClose: true,
                message: this.$t('Tip.Inconsistent'),
                type: 'warning',
                duration: 1000
              })
              return
            }
            if (params.password.length < 6) {
              this.$message({
                showClose: true,
                message: this.$t('Tip.Digits'),
                type: 'warning',
                duration: 1000
              })
              return
            }

            resetPwd(params, lang).then((response) => {
              if (response.code === 200) {
                this.$message({
                  showClose: true,
                  message: this.$t('Tip.Modified'),
                  type: 'success',
                  duration: 1000
                })
                this.isLogin = true
              }
            })
          } else {
            return false
          }
        })
      } else {
        this.$refs.loginPhone.validate((valid) => {
          if (valid) {
            if (!this.repeatPhone) {
              return
            }
            this.repeatPhone = false
            setTimeout(() => {
              this.repeatPhone = true
            }, 2000)
            if (!params.password) {
              this.$message({
                showClose: true,
                message: this.$t('Setting.IpPwd'),
                type: 'warning',
                duration: 1000
              })
              return
            }
            if (!params.verify_code) {
              this.$message({
                showClose: true,
                message: this.$t('Login.verificationCode'),
                type: 'warning',
                duration: 1000
              })
              return
            }
            if (this.loginPhone.SetPassword !== this.loginPhone.ConfirePassword) {
              this.$message({
                showClose: true,
                message: this.$t('Tip.Inconsistent'),
                type: 'warning',
                duration: 1000
              })
              return
            }
            if (params.password.length < 6) {
              this.$message({
                showClose: true,
                message: this.$t('Tip.Digits'),
                type: 'warning',
                duration: 1000
              })
              return
            }
            params.password = this.$md5(params.password)
            resetPwd(params, lang).then((response) => {
              if (response.code === 200) {
                this.$message({
                  showClose: true,
                  message: this.$t('Tip.Modified'),
                  type: 'success',
                  duration: 1000
                })
                this.isLogin = true
              }
            })
          } else {
            return false
          }
        })
      }
    },
    // 手机号校验
    isCellPhone (val) {
      if (!/^1(3|4|5|6|7|8)\d{9}$/.test(val)) {
        return false
      } else {
        return true
      }
    },
    // 获取邮箱验证码
    sendEmailCode () {
      const params = {}
      params.send_type = 'email'
      params.verify_body = this.loginEmail.email
      const lang = localStorage.getItem('language')
      this.$refs.loginEmail.validateField('email', (error) => {
        if (!error) {
          emailCode(params, lang).then((response) => {
            const TIME_COUNT = 60
            if (!this.timer) {
              this.count = TIME_COUNT
              this.isSendEmail = true
              // 60秒倒计时
              this.timer = setInterval(() => {
                if (this.count > 0 && this.count <= TIME_COUNT) {
                  this.count--
                } else {
                  this.isSendEmail = false
                  clearInterval(this.timer)
                  this.timer = null
                }
              }, 1000)
            }
          })
        }
      })
    },
    // 获取手机验证码
    sendPhoneCode () {
      const params = {}
      params.send_type = 'mobile'
      params.verify_body = this.loginPhone.phone
      const lang = localStorage.getItem('language')
      this.$refs.loginPhone.validateField('phone', (error) => {
        if (!error) {
          emailCode(params, lang).then((response) => {
            const TIME_COUNT = 60
            if (!this.timer) {
              this.count = TIME_COUNT
              this.isSendEmail = true
              this.timer = setInterval(() => {
                if (this.count > 0 && this.count <= TIME_COUNT) {
                  this.count--
                } else {
                  this.isSendEmail = false
                  clearInterval(this.timer)
                  this.timer = null
                }
              }, 1000)
            }
          })
        }
      })
    },
    // 切换手机和邮箱修改密码窗口
    checkTab (val) {
      this.tab = val
      if (val === '1') {
        this.$nextTick(() => {
          this.timer = null
          this.isSendEmail = false
          this.count = 0
          this.$refs.loginPhone.clearValidate()
        })
      } else {
        this.$nextTick(() => {
          this.timer = null
          this.isSendEmail = false
          this.count = 0
          this.$refs.loginEmail.clearValidate()
        })
      }
    },
    // 返回登录页
    backLogin () {
      this.isLogin = true
    },
    // 切换到修改密码页
    CheckForget () {
      this.isLogin = false
    },
    // 登陆API
    async Login () {
      if (!this.repeat) {
        return
      }
      this.repeat = false
      setTimeout(() => {
        this.repeat = true
      }, 2000)
      if (!this.loginInfo.password || !this.loginInfo.username) {
        this.$message({
          showClose: true,
          message: this.$t('Tip.Blank'),
          type: 'warning',
          duration: 1000
        })
        return
      }

      const params = {}
      params.account = this.loginInfo.username
      params.password = this.$md5(this.loginInfo.password)
      params.platform = 'web'
      const lang = localStorage.getItem('language')
      Login(params, lang).then((res) => {
        if (res.code === 200) {
          // 登陆成功后存储toker、用户昵称和用户角色等并跳转到设备页面
          localStorage.setItem('token', res.data.token)
          localStorage.setItem('account', params.account)
          localStorage.setItem('nickname', res.data.nickname)

          localStorage.setItem('rid', res.data.rid)
          this.$router.push('/Machine')
        }
      }).catch(() => {

      })
    },
    // 切换中英文
    handleSetLanguage (val) {
      if (val === 'zh') {
        localStorage.setItem('language', 'zh_CN')
        this.$i18n.locale = 'zh'
      } else {
        localStorage.setItem('language', 'en_US')
        this.$i18n.locale = 'en'
      }
    },
    // 调用获取语言栏API（暂时还没有做，动态获取多语言文件）
    getlanguage () {
      getlanguage().then((res) => {
        if (res.code === 200) {

        }
      }).catch(() => {

      })
    }
  }

}
</script>
