import axios from 'axios'
import { Message } from 'element-ui'
import routers from '../router'
var repeat = true
const service = axios.create({
  baseURL: 'https://api.yudotmc.com',
  timeout: 30000 // request timeout
})
// service.interceptors.request.use(
//   config => {
//     config.headers.accept = 'application/json,text/plain,text/html,application/xhtml+xml,application/xml;q=0.9,image/avif,image/webp,image/apng,*/*;q=0.8,application/signed-exchange;v=b3;q=0.9'
//     return config
//   },
//   error => {
//     // do something with request error
//     return Promise.reject(error)
//   }
// )
service.interceptors.response.use(
  response => {
    if (response.data.code === 200) {
      const res = response.data
      return res
    } else {
      Message({
        showClose: true,
        message: response.data.message,
        type: 'warning',
        duration: 1000
      })
      const res = response.data
      return res
      // return Promise.reject(response.data)
    }
  },
  error => {
    if (error.response.request.status === 403 || error.response.request.status === 401 || error.response.request.status === 407) {
      if (repeat) {
        Message({
          showClose: true,
          message: error.response.data.message,
          type: 'warning',
          duration: 1000
        })
      }
      repeat = false
      routers.push('/')
    }
    return Promise.reject(error)
  }
)

export default service
