import request from '@/js/request'
// 获取用户信息
export function getUserInfo (data) {
  return request({
    url: '/admin/info',
    method: 'get',
    params: data
  })
}

// 更改个人信息
export function changeInfo (data, param, lang) {
  return request({
    url: '/admin/updateSelf?token=' + param + '&language=' + lang,
    method: 'post',
    data: data
  })
}

// 修改密码
export function changePwd (data, param, lang) {
  return request({
    url: '/admin/updatePwd?token=' + param + '&language=' + lang,
    method: 'post',
    data: data
  })
}
