<template>
  <div class="app-wrapper">

    <main-left />

    <main-content />
  </div>
</template>

<script>
import { MainLeft, MainContent } from './components'

export default {
  name: 'Layout',
  components: {
    MainLeft,
    MainContent
  },
  computed: {

  },
  methods: {

  }
}
</script>

<style lang="less" scoped>
.app-wrapper{
  width: 100%;
  height: 100%;
  display: flex;
}

</style>
