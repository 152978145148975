import Vue from 'vue'
import VueI18 from 'vue-i18n'
import Cookies from 'js-cookie'
import elementEnlocale from 'element-ui/lib/locale/lang/en' // lang i18n
import elementZhlocale from 'element-ui/lib/locale/lang/zh-CN' // lang i18n
import ElementLocale from 'element-ui/lib/locale'
import enLocal from './en'
import zhLocal from './zh'
Vue.use(VueI18)
const messages = {
  zh: {
    ...elementZhlocale,
    ...zhLocal
  },
  en: {
    ...elementEnlocale,
    ...enLocal
  }
}
export function getLanguage () {
  const chooseLanguage = Cookies.get('language')
  if (chooseLanguage) return chooseLanguage
  return 'zh'
}
const i18n = new VueI18({
  local: getLanguage(),
  messages
})

ElementLocale.i18n((key, value) => i18n.t(key, value))
export default i18n
