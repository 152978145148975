<template>
  <section class="app-main">
    <transition name="fade-transform" mode="out-in">
      <router-view :key="key" />
    </transition>
  </section>
</template>

<script>
export default {
  name: 'MainContent',
  computed: {
    key () {
      return this.$route.path
    }
  },
  methods: {

  }
}

</script>

<style scoped>
.app-main {
  height: 100%;
  width: calc(100vw - 156px);
  position: relative;
  overflow: hidden;
  background-color: #D2D2D2;
}

</style>
