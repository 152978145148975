import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    tempActiveComponent: 0, //0是文本模式 1是图纸模式
    timerActiveComponent: 0,
  },
  getters: {
    tempActiveComponent: (state) => state.tempActiveComponent,
    timerActiveComponent: (state) => state.timerActiveComponent,
  },
  mutations: {
    setTempActiveComponent(state, val) {
      state.tempActiveComponent = val;
    },
    setTimerActiveComponent(state, val) {
      state.timerActiveComponent = val;
    },
  },
  actions: {},
  modules: {},
});
