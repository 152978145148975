<template>
  <div class="mainLeft">
    <div class="left_Title">
      <img src='../../assets/image/tmcIcon.png'/>
    </div>
    <div class="left_content">
      <div class="left_list" @click="changeSelected(0)">
        <div class="list_color" :style="{background:isSelected==0?'#4875ef':''}" />
        <div class="list_icon ic1" />
        <div class="list_text" :style="{color:isSelected==0?'#fff':'#d6d9e1',fontWeight:isSelected==0?'700':'400'}">{{$t('Machine.Equipment')}}</div>
      </div>
      <div class="left_list" @click="changeSelected(1)">
        <div class="list_color" :style="{background:isSelected==1?'#4875ef':''}" />
        <div class="list_icon ic2" />
        <div class="list_text" :style="{color:isSelected==1?'#fff':'#d6d9e1',fontWeight:isSelected==1?'700':'400'}">{{$t('Machine.Alarm')}}</div>
      </div>
      <div class="left_list" @click="changeSelected(2)">
        <div class="list_color" :style="{background:isSelected==2?'#4875ef':''}" />
        <div class="list_icon ic3" />
        <div class="list_text" :style="{color:isSelected==2?'#fff':'#d6d9e1',fontWeight:isSelected==2?'700':'400'}">{{$t('Machine.Setting')}}</div>
      </div>
    </div>
    <div class="left_bottom">
      <div class="layout" @click="toLogin"/>
      <div class="userName" @click="userInfo">{{account}}</div>
      <div class="time">{{date}} {{time}}</div>
      <div class="selected">
        <el-select v-model="companyType" @change="changeLan">
          <el-option v-for="item in companyTypes" :key="item.id" :label="item.value" :value="item.id" />
        </el-select>
      </div>
    </div>
    <user-info ref="UserInfo"></user-info>
  </div>
</template>

<script>
import UserInfo from '../components/userInfo.vue'
export default {
  name: 'MainLeft',
  components: {
    UserInfo
  },
  data () {
    return {
      isSelected: 0, // 设置、报警信息、设备 导航栏选择
      companyType: 'zh', // 当前语言
      account: '', // 左下角账号名
      dataTimer: '', // 时间定时器
      date: '', // 左下角日期
      time: '', // 左下角时间
      // 多语言数组
      companyTypes: [{
        value: '中文',
        id: 'zh'
      }, {
        value: 'English',
        id: 'en'
      }]
    }
  },

  created () {
    this.getTime()
    // 初始化当前的路由
    this.isSelected = this.$route.name === 'Warning' ? 1 : this.$route.name === 'Set' ? 2 : 0
    this.account = localStorage.getItem('nickname')
    if (localStorage.getItem('language')) {
      this.$i18n.locale = localStorage.getItem('language') === 'zh_CN' ? 'zh' : 'en'
      this.companyType = localStorage.getItem('language') === 'zh_CN' ? 'zh' : 'en'
    } else {
      localStorage.setItem('language', 'zh_CN')
      this.$i18n.locale = 'zh'
      this.companyType = 'zh'
    }
    this.$bus.$on('passAllow', value => {
      this.passAllow(value)
    })
    // 将用户信息页面修改后的账户名赋值给左下角
    this.$bus.$on('changeNick', value => {
      this.changeNick(value)
    })
  },
  mounted () {
    // eslint-disable-next-line no-return-assign
    window.addEventListener('resize', () => this.screenWidth = document.documentElement.clientWidth, false)
    // 每隔一分钟请求一次数据,单纯的使用setInterval 会使浏览器崩溃，要配合setTimeout(自带清除定时器)使用
    this.dataTimer = window.setInterval(() => {
      this.getTime()
    }, 60000)
  },
  destroyed () {
    // 页面销毁前清除定时器
    if (this.dataTimer) { // 定时加载数据关闭
      clearInterval(this.dataTimer)
      this.dataTimer = ''
    }
  },
  methods: {
    // 将用户信息页面修改后的账户名赋值给左下角
    changeNick (value) {
      console.log(value)
      this.account = value
    },
    // 切换语言
    changeLan (val) {
      if (val === 'zh') {
        localStorage.setItem('language', 'zh_CN')
      } else {
        localStorage.setItem('language', 'en_US')
      }
      this.$i18n.locale = val
      // this.cache.put('lang', val)
    },
    // 跳转到登录页
    toLogin () {
      this.$router.push('/')
    },
    // 点击显示用户信息
    userInfo () {
      this.$refs.UserInfo.showUser()
    },
    // 获取当前时间
    getTime () {
      const nowDate = new Date()
      const year = nowDate.getFullYear()
      const month = nowDate.getMonth() + 1 >= 10 ? nowDate.getMonth() + 1 : '0' + (nowDate.getMonth() + 1)
      const day = nowDate.getDate() >= 10 ? nowDate.getDate() : '0' + nowDate.getDate()
      const hour = nowDate.getHours() >= 10 ? nowDate.getHours() : '0' + nowDate.getHours()
      const minute = nowDate.getMinutes() >= 10 ? nowDate.getMinutes() : '0' + nowDate.getMinutes()
      this.date = year + '/' + month + '/' + day
      this.time = hour + ':' + minute
    },
    // 点击跳转到不同页面
    changeSelected (val) {
      this.isSelected = val
      if (val === 0) {
        this.$router.push({ path: '/Machine' })
      } else if (val === 1) {
        this.$router.push({ path: '/Warning' })
      } else {
        this.$router.push({ path: '/Set' })
      }
    }
  }
}
</script>

<style lang="less" scoped>

.mainLeft{
  width: 156px;
  height: 100%;
  background: #353e5a;
  position: relative;
  .left_content{
    width: 100%;
    margin-top: 10px;
    .left_list{
      width: 100%;
      height: 30px;
      float: left;
      line-height: 30px;
      font-size: 17px;
      margin: 20px 12px;
      cursor: pointer;
      overflow:hidden;
      .list_color{
        width:6px;
        height: 22px;
        border-radius: 6px;
        float: left;
        margin-top: 4px;
      }
      .list_icon{
        width: 28px;
        height: 28px;
        float: left;
        margin-left: 5px;
        background-repeat: no-repeat;

      }
      .ic1{
        background-image: url('../../assets/image/machine.png');
        background-size: 22px 22px;
        background-position: 4px 4px;
      }
      .ic2{
        background-image: url('../../assets/image/警报@2x.png');
        background-size: 28px 28px;
        background-position: 1px 1px;
      }
      .ic3{
        background-image: url('../../assets/image/设定@2x.png');
        background-size: 28px 28px;
        background-position: 1px 1px;
      }
      .list_text{
        float: left;
        margin-left: 10px;
        color:#d6d9e1
      }
    }
  }
  .left_Title{
    width: 100%;
    height: 44px;
    color:#d6d9e1;
    font-size: 20px;
    line-height: 44px;
    text-align: left;
    text-indent: 12px;
    img{
      width: 120px;
      height: 45px;
      margin-top: 16px;
      margin-left: 10px;
      float: left;
    }
  }
  .left_bottom{
    .layout{
      position: absolute;
      bottom: 100px;
      width:28px;
      height: 28px;
      left: 20px;
      background-image: url('../../assets/image/退出 (1)@2x.png');
      background-repeat: no-repeat;
      background-size: cover;
      cursor: pointer;
    }
    .userName{
      width: 100%;
      text-align: left;
      text-indent: 20px;
      bottom: 70px;
      color: #d6d9e1;
      font-size: 14px;
      position: absolute;
      cursor: pointer;
    }
    .time{
      width: 100%;
      text-align: left;
      text-indent: 20px;
      bottom: 45px;
      color: #d6d9e1;
      font-size: 14px;
      position: absolute;
    }
    .selected{
      width: 70%;
      margin-left: 20px;
      bottom: 10px;
      position: absolute;

      /deep/ .el-input__inner{
        height: 26px;
        line-height: 26px;
        background-color: #353e5a;
        border: 2px solid #8f94a4;
        color:#d6d9e1;
        padding-left: 8px;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
      }
      /deep/ .el-select{
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
      }
      /deep/ .el-input__icon {
        width: 26px;
        line-height: 26px;
      }
      /deep/ .el-icon-arrow-up:before {
        content: "\e6e1";
        font-size: 16px;
        margin-top: 4px;
      }
      /deep/ .el-select .el-input.is-focus .el-input__inner {
         border: 2px solid #8f94a4;
      }
      /deep/ .el-select .el-input__inner:focus {
         border: 2px solid #8f94a4;
      }
      /deep/ .el-select:hover .el-input__inner {
         border: 2px solid #8f94a4;
      }
    }
  }
}
</style>
